import { FC, FormEvent, useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { createUser } from '../../lib/requests';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';
import { PageFooter } from '../PageFooter';
import FormWrapper from './FormWrapper';

export interface RegisterUserProps {
  fetchUsers: () => void;
}

const RegisterUser: FC<RegisterUserProps> = ({ fetchUsers }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    createUser(currentUser!.token, { email, password, isAdmin })
      .then(() =>
        toast({
          title: currentLocale.newUserAlert,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch((err) => {
        if (err.response.data.message) {
          toast({
            title: err.response.data.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        } else {
          toast({
            title: currentLocale.registerFailedAlert,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      })
      .finally(() => {
        fetchUsers();
        setIsLoading(false);
      });
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit}>
        <VStack spacing='6' w='full' alignItems='flex-start'>
          <FormControl isRequired>
            <FormLabel mt={4}>{currentLocale.email}:</FormLabel>
            <Input
              bg='formInputBg'
              type='email'
              placeholder={currentLocale.emailPlaceholder}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{currentLocale.password}:</FormLabel>
            <Input
              bg='formInputBg'
              type='password'
              placeholder={currentLocale.passwordPlaceholder}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <Input
              bg='formInputBg'
              type='password'
              placeholder={currentLocale.confirmPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <HStack>
              <FormLabel>{currentLocale.adminChkBox}:</FormLabel>
              <Checkbox onChange={(e) => setIsAdmin(e.target.checked)} />
            </HStack>
          </FormControl>

          <PageFooter>
            <Button isLoading={isLoading} type='submit'>
              {currentLocale.registerBtn}
            </Button>
          </PageFooter>
        </VStack>
      </form>
    </FormWrapper>
  );
};

export default RegisterUser;
