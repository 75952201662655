import { RepeatIcon } from '@chakra-ui/icons';
import {
  Text,
  Select,
  Stack,
  StackDivider,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IUserDTO } from '../../types/UserDto';
import FormWrapper from './FormWrapper';
import UserEditForm from './UserEditForm';

export interface UserManagementProps {
  users: IUserDTO[];
  fetchUsers: () => void;
}

const UserManagement: React.FC<UserManagementProps> = ({
  users,
  fetchUsers,
}) => {
  const [selectedUser, setSelectedUser] = useState<IUserDTO>(users[0]);

  const handleUserChange = (userId: string) => {
    const _user = users.find((u) => u.id === userId)!;
    setSelectedUser(_user);
  };

  return (
    <FormWrapper>
      <Stack spacing='4' divider={<StackDivider />}>
        <Box maxW='40%' mb='8'>
          <Text>Select user</Text>

          <Stack isInline>
            <Select onChange={(e) => handleUserChange(e.target.value)}>
              {users.map((u) => (
                <option key={u.id} value={u.id}>
                  {u.email}
                </option>
              ))}
            </Select>

            <IconButton
              onClick={() => fetchUsers()}
              aria-label='Refresh users list'
              icon={<RepeatIcon />}
            />
          </Stack>
        </Box>

        <UserEditForm
          user={selectedUser}
          setUser={setSelectedUser}
          fetchUsers={fetchUsers}
        />
      </Stack>
    </FormWrapper>
  );
};

export default UserManagement;
