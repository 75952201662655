import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  IconButton,
  Wrap,
  WrapItem,
  CloseButton,
  Button,
  Select,
  NumberInput,
  NumberInputField,
  useToast,
  SkeletonText,
  Box,
  Center,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import ReactSelect, { createFilter, MultiValue } from 'react-select';

import { getOptions } from '../../lib/utils';
import { fetchUserById, fetchData } from '../../lib/requests';
import { LocaleContext } from '../../context/localeContext';
import { UserContext } from '../../context/userContext';
import { IConfiguration } from '../../types/Configuration';
import { IAkeneoVersion } from '../../types/akeneoVersion';
import { IOption, IOptions } from '../../types/options';

export interface ConfigurationFormProps {
  configuration: IConfiguration;
  setConfiguration: Dispatch<SetStateAction<IConfiguration>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const ConfigurationForm: FC<ConfigurationFormProps> = ({
  configuration,
  setConfiguration,
  setIsLoading,
  isLoading,
}) => {
  const [akeneoVersion, setAkeneoVersion] = useState<IAkeneoVersion>();
  const [rootCatalogInput, setRootCatalogInput] = useState(
    configuration.catalogRoot
  );

  const [options, setOptions] = useState<IOptions>({
    channels: [],
    locales: [],
    rootCatalogs: [],
    categories: [],
    attributes: [],
    attributeGroups: [],
  });

  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  useEffect(() => {
    const token = currentUser!.token;
    setIsLoading(true);

    Promise.all([
      // Get and set options. Overwrites all existing options.
      getOptions(token, currentLocale)
        .then((newOpts) =>
          setOptions((opts) => {
            return { ...opts, ...newOpts };
          })
        )
        .catch((err) => {
          throw new Error(err.message);
        }),

      // Fetch user data to determine used Akeneo version
      fetchUserById(currentUser!)
        .then((data) => {
          setAkeneoVersion(data.akeneoVersion);

          if (data.akeneoVersion.version === 'saas') {
            // If Akeneo version is Serenity fetch catalog root
            fetchData('configuration-options/catalog-root', token)
              .then((data: IOption[]) =>
                setOptions((o) => {
                  return { ...o, rootCatalogs: data };
                })
              )
              .catch(() => {
                throw new Error(currentLocale.rootCatalogFetchFail);
              });
          }
        })
        .catch(() => {
          throw new Error(currentLocale.userDataFetchFail);
        }),
    ])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) =>
        toast({
          title: err.message,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  }, [currentLocale, currentUser, setIsLoading, toast]);

  // This useEffect will fetch all categories under selected catalog root.
  // Every time catalog root changes new categories will be fetched.
  useEffect(() => {
    setConfiguration((c) => {
      return { ...c, category: '' };
    });

    if (configuration.catalogRoot) {
      fetchData(
        'configuration-options/categories',
        currentUser!.token,
        `root=${configuration.catalogRoot}`
      )
        .then((data: IOption[]) => {
          setOptions((opts) => {
            return {
              ...opts,
              categories: data,
            };
          });
        })
        .catch(() =>
          toast({
            title: currentLocale.categoryFetchFail,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          })
        );
    }
  }, [
    currentLocale,
    configuration.catalogRoot,
    setConfiguration,
    currentUser,
    toast,
  ]);

  // If all locales all selected, quality score will be empty.
  useEffect(() => {
    if (configuration.locale.includes(',')) {
      setConfiguration((c) => {
        return { ...c, qualityScore: '' };
      });
    }
  }, [configuration.locale, setConfiguration]);

  // If scope changes, selected locale, catalog root and category
  // will to be reset
  useEffect(() => {
    setConfiguration((c) => {
      return {
        ...c,
        locale: '',
        catalogRoot: '',
        category: '',
      };
    });
  }, [configuration.scope, setConfiguration]);

  const handleAttributeChange = (value: string) => {
    const valueArr = value.split(',');
    const filtered = valueArr.filter(
      (a) => !configuration.attributes.includes(a)
    );

    setConfiguration((c) => {
      return {
        ...c,
        attributes: [...c.attributes, ...filtered],
      };
    });
  };

  const handleLocaleChange = (
    e: MultiValue<{ label: string; value: string }>
  ) => {
    const locales = e.map((l) => l.value).join(',');
    setConfiguration({
      ...configuration,
      locale: locales,
    });
  };

  const handleCategoryChange = (
    e: MultiValue<{ label: string; value: string }>
  ) => {
    const categories = e.map((c) => c.value).join(',');
    setConfiguration({
      ...configuration,
      category: categories,
    });
  };

  const handleAttributeRemove = (filterCode: string) => {
    const filtered = configuration.attributes.filter(
      (code) => code !== filterCode
    );

    setConfiguration((c) => {
      return {
        ...c,
        attributes: filtered,
      };
    });
  };

  const handleAttributeClear = () => {
    setConfiguration((c) => {
      return { ...c, attributes: [] };
    });
  };

  const handleRootCatalogSearch = async () => {
    await fetchData(
      'configuration-options/validate-root-catalog',
      currentUser!.token,
      `code=${rootCatalogInput}`
    )
      .then(() => {
        setConfiguration({
          ...configuration,
          catalogRoot: rootCatalogInput,
        });

        toast({
          title: `Root catalog code: ${rootCatalogInput} found`,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      })
      .catch(() =>
        toast({
          title: currentLocale.rootCatalogNotFound,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  };

  const localeOptions = options.channels.find(
    (c) => c.code === configuration.scope
  )?.locales;

  const filter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'start',
    trim: true,
  });

  if (!isLoading) {
    return (
      <VStack w='full' alignItems='flex-start'>
        <VStack w='50%' spacing='6'>
          <FormControl isRequired>
            <FormLabel fontSize='xs'>{currentLocale.confName}</FormLabel>
            <Input
              value={configuration.name}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  name: e.target.value,
                })
              }
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize='xs'>{currentLocale.confScope}</FormLabel>
            <Select
              placeholder={currentLocale.confScopePlaceholder}
              value={configuration.scope}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  scope: e.target.value,
                })
              }
            >
              {options.channels.map((c, index) => (
                <option key={index} value={c.code}>
                  {c.labels['en_US'] || c.code}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize='xs'>{currentLocale.confLocale}</FormLabel>
            <ReactSelect
              isMulti
              isDisabled={!!!configuration.scope}
              placeholder={currentLocale.confLocalePlaceholder}
              options={localeOptions}
              onChange={(e) => handleLocaleChange(e)}
              value={localeOptions?.filter((l) =>
                configuration.locale.includes(l.value)
              )}
            />
          </FormControl>
        </VStack>

        <HStack spacing='4' w='full'>
          <FormControl isRequired>
            <FormLabel fontSize='xs'>{currentLocale.confRootCatalog}</FormLabel>

            {akeneoVersion!.version === 'saas' ? (
              <ReactSelect
                placeholder={currentLocale.confRootCatalogPlaceholder}
                isDisabled={!!!configuration.scope}
                options={options.rootCatalogs}
                value={options.rootCatalogs.find(
                  (catalog) => catalog.value === configuration.catalogRoot
                )}
                onChange={(e) => {
                  if (e) {
                    setConfiguration({
                      ...configuration,
                      catalogRoot: e.value,
                    });
                  }
                }}
              />
            ) : (
              <HStack>
                <Input
                  placeholder={currentLocale.confRootCatalogCode}
                  disabled={!!!configuration.scope}
                  value={rootCatalogInput}
                  onChange={(e) => setRootCatalogInput(e.target.value)}
                />
                <IconButton
                  aria-label={currentLocale.confRootCatalogSearchAriaLabel}
                  icon={<SearchIcon />}
                  onClick={() => handleRootCatalogSearch()}
                />
              </HStack>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontSize='xs'>{currentLocale.confCategory}</FormLabel>

            <ReactSelect
              filterOption={filter}
              isMulti
              isSearchable
              isClearable
              closeMenuOnSelect={false}
              isDisabled={!!!configuration.catalogRoot}
              placeholder={currentLocale.confCategoryPlaceholder}
              onChange={(e) => handleCategoryChange(e)}
              options={options.categories}
              value={options.categories.filter((c) =>
                configuration.category.split(',').find((x) => x === c.value)
              )}
            />
          </FormControl>
        </HStack>

        <VStack alignItems='flex-start' w='full' spacing='6'>
          <Text fontSize='xs'>{currentLocale.confAttributes}</Text>
          <Wrap
            border='1px dotted #ccc'
            p='6'
            borderRadius='lg'
            spacing='2'
            w='full'
            h='100%'
          >
            {configuration.attributes.length ? (
              configuration.attributes.map((code, index) => {
                const { label } = options.attributes.find(
                  (a) => a.value === code
                )!;

                return (
                  <WrapItem key={index}>
                    <HStack
                      bg='green.100'
                      borderRadius='lg'
                      fontSize='xs'
                      color='green.800'
                    >
                      <Text py='2' px='4'>
                        {label || code}
                      </Text>
                      <CloseButton
                        size='md'
                        onClick={() => handleAttributeRemove(code)}
                      />
                    </HStack>
                  </WrapItem>
                );
              })
            ) : (
              <Center w={'full'}>
                <Text fontSize={'xs'} opacity={'50%'}>
                  Select attributes from below. If none are selected all
                  attributes will be included.
                </Text>
              </Center>
            )}
          </Wrap>
          <Button onClick={() => handleAttributeClear()} size='xs'>
            {currentLocale.clearAttributes}
          </Button>
        </VStack>

        <HStack w='full' py='6' spacing='4'>
          <FormControl>
            <ReactSelect
              isSearchable
              filterOption={filter}
              closeMenuOnSelect={false}
              placeholder={currentLocale.addAttributesByGroup}
              controlShouldRenderValue={false}
              onChange={(e: any) => handleAttributeChange(e.value)}
              options={options.attributeGroups}
            />
          </FormControl>

          <FormControl>
            <ReactSelect
              isSearchable
              filterOption={filter}
              closeMenuOnSelect={false}
              placeholder={currentLocale.addAttributes}
              controlShouldRenderValue={false}
              onChange={(e: any) => handleAttributeChange(e.value)}
              options={options.attributes}
            />
          </FormControl>
        </HStack>

        <HStack maxW='min-content' mb='10' spacing='4'>
          <FormControl minW='125' isRequired>
            <FormLabel fontSize='xs'>
              {currentLocale.confCompleteness}
            </FormLabel>
            <NumberInput
              min={0}
              max={100}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  completeness: parseInt(e),
                })
              }
              value={configuration.completeness}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
          {akeneoVersion?.version === 'saas' && (
            <FormControl
              minW='100'
              isDisabled={configuration.locale.includes(',')}
            >
              <FormLabel fontSize='xs'>
                {currentLocale.confQualityScore}
              </FormLabel>

              <Select
                value={configuration.qualityScore}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    qualityScore: e.target.value,
                  })
                }
              >
                <option value=''>{currentLocale.qualityScoreNone}</option>
                <option value='A'>A</option>
                <option value='B'>B</option>
                <option value='C'>C</option>
                <option value='D'>D</option>
                <option value='E'>E</option>
              </Select>
            </FormControl>
          )}
        </HStack>
      </VStack>
    );
  } else {
    return (
      <Box w='75%'>
        <SkeletonText mt='4' noOfLines={5} spacing='4' />
      </Box>
    );
  }
};

export default ConfigurationForm;
