import React, { FC } from 'react';
import TopBar from '../components/Topbar/TopBar';
import { Flex, Box } from '@chakra-ui/react';

const ViewContainer: FC = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Box sx={{ '--sidebar-width': '16rem', '--topbar-height': '50px' }}>
      <TopBar />
      <Flex overflow='hidden'>
        {children}
      </Flex>
    </Box>
  );
};

export default ViewContainer;
