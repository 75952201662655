import React, { FC, useContext, useState } from 'react';
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Input,
  IconButton,
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  useToast,
  Switch,
} from '@chakra-ui/react';
import { IUrls } from '../../types/configurationDto';
import { LocaleContext } from '../../context/localeContext';

export interface EndpointsProps {
  enpointUrls: IUrls;
}

const Endpoints: FC<EndpointsProps> = ({ enpointUrls }) => {
  const [categoriesUrl, setCategoriesUrl] = useState(enpointUrls.categoriesUrl);
  const [attributesUrl, setAttributesUrl] = useState(enpointUrls.attributesUrl);
  const [isGetAll, setIsGetAll] = useState(false);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  const handleCopy = (text: string) => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() =>
        toast({
          title: currentLocale.copiedAlert,
          status: 'info',
          position: 'bottom-right',
          duration: 1000,
        })
      );
    } else {
      toast({
        title: 'Copy only works in secure context (HTTPS)',
        status: 'error',
        position: 'bottom-right',
        isClosable: true,
      });
    }
  };

  const handleToggle = (isChecked: boolean) => {
    setIsGetAll(isChecked);
    if (isChecked) {
      setAttributesUrl(enpointUrls.attributesUrl.split('&')[0]);
      setCategoriesUrl(enpointUrls.categoriesUrl.split('&')[0]);
    } else {
      setAttributesUrl(enpointUrls.attributesUrl);
      setCategoriesUrl(enpointUrls.categoriesUrl);
    }
  };

  const handleOpenClick = (url: string) => window.open(url, '_blank');

  return (
    <Box my='6'>
      <Stack spacing='6' width='full'>
        <FormControl>
          <FormLabel>
            Get all entities instead of configuration specific (not including
            products).
          </FormLabel>
          <Switch onChange={(e) => handleToggle(e.target.checked)} />
        </FormControl>
        <FormControl>
          <FormLabel>{currentLocale.products}:</FormLabel>
          <HStack>
            <Input textOverflow='ellipsis' value={enpointUrls.productsUrl} />
            <IconButton
              aria-label='Copy Products Url'
              icon={<CopyIcon />}
              onClick={() => handleCopy(enpointUrls.productsUrl)}
              ml={2}
            />
            <IconButton
              aria-label='Open products xml in new tab'
              icon={<ExternalLinkIcon />}
              onClick={() => handleOpenClick(enpointUrls.productsUrl)}
            />
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel>
            {currentLocale.categories}{' '}
            {isGetAll ? '(all)' : '(configuration specific)'}:
          </FormLabel>
          <HStack>
            <Input textOverflow='ellipsis' value={categoriesUrl} />
            <IconButton
              aria-label='Copy categories url'
              icon={<CopyIcon />}
              onClick={() => handleCopy(categoriesUrl)}
              ml={2}
            />
            <IconButton
              aria-label='Open categories xml in new tab'
              icon={<ExternalLinkIcon />}
              onClick={() => handleOpenClick(categoriesUrl)}
            />
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel>
            {currentLocale.confAttributes}{' '}
            {isGetAll ? '(all)' : '(configuration specific)'}:
          </FormLabel>
          <HStack>
            <Input textOverflow='ellipsis' value={attributesUrl} />
            <IconButton
              aria-label='Copy attributes url'
              icon={<CopyIcon />}
              onClick={() => handleCopy(attributesUrl)}
              ml={2}
            />
            <IconButton
              aria-label='Open attribute xml in new tab'
              icon={<ExternalLinkIcon />}
              onClick={() => handleOpenClick(attributesUrl)}
            />
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel>{currentLocale.attributeGroups} (all):</FormLabel>
          <HStack>
            <Input
              textOverflow='ellipsis'
              value={enpointUrls.attributeGroupsUrl}
            />
            <IconButton
              aria-label='Copy attribute groups url'
              icon={<CopyIcon />}
              onClick={() => handleCopy(enpointUrls.attributeGroupsUrl)}
              ml={2}
            />
            <IconButton
              aria-label='Open attribute groups xml in new tab'
              icon={<ExternalLinkIcon />}
              onClick={() => handleOpenClick(enpointUrls.attributeGroupsUrl)}
            />
          </HStack>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default Endpoints;
