import { createContext } from 'react';
import { IUser } from '../types/User';

interface IUserContext {
  currentUser: IUser | null;
  setCurrentUser: (user: IUser | null) => void;
}

export const UserContext = createContext<IUserContext>({
  currentUser: null,
  setCurrentUser: () => {},
});
