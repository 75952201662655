import { fetchData } from './requests';
import { ILanguage } from '../types/langs';
import { IChannel, IOption, IOptions } from '../types/options';

/**
 *
 * @param token JWT for auth.
 * @param currentLocale Currently selected locale.
 * @returns Scopes (channels), attributes, and attribute groups.
 */
export const getOptions = async (token: string, currentLocale: ILanguage) => {
  const options: Partial<IOptions> = {};

  return Promise.all([
    fetchData('configuration-options/channels', token)
      .then((data: IChannel[]) => (options.channels = data))
      .catch(() => {
        throw new Error(currentLocale.scopeFetchFail);
      }),

    fetchData('configuration-options/attributes', token)
      .then((data: IOption[]) => (options.attributes = data))
      .catch(() => {
        throw new Error(currentLocale.attributeFetchFail);
      }),

    fetchData('configuration-options/attribute-groups', token)
      .then((data: IOption[]) => (options.attributeGroups = data))
      .catch(() => {
        throw new Error(currentLocale.attGroupFetchFail);
      }),
  ])
    .then(() => options)
    .catch((err) => {
      throw err;
    });
};

export const getCategoriesByRootCatalog = async (
  rootCatalogCode: string,
  token: string
) => {
  return fetchData(
    'configuration-options/categories',
    token,
    `root=${rootCatalogCode}`
  )
    .then((data: IOption[]) => data)
    .catch((err) => {
      throw err;
    });
};
