import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { HiUserCircle } from 'react-icons/hi';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';
import { NavItem } from './NavItem';
import { NavLink } from 'react-router-dom';

const ProfileIcon = () => {
  return <NavItem aria-hidden icon={HiUserCircle} />;
};

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as='button'
      flexShrink={0}
      rounded='full'
      outline='0'
      _focus={{ shadow: 'outline' }}
    >
      <ProfileIcon />
      <Box srOnly>Open user menu</Box>
    </Flex>
  );
};

export const ProfileDropdown = () => {
  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList
        rounded='md'
        shadow='lg'
        py='1'
        zIndex='11'
        color={mode('gray.600', 'inherit')}
        fontSize='sm'
      >
        <HStack px='3' py='4'>
          <ProfileIcon />
          <Box lineHeight='1'>
            <Text mt='1' fontSize='xs' color='gray.500'>
              {currentUser!.email}
            </Text>
          </Box>
        </HStack>

        <MenuItem
          as={NavLink}
          to='/logout'
          fontWeight='medium'
          color={mode('red.500', 'red.300')}
        >
          {currentLocale.logout}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
