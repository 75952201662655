import { Box, Button, Center, Link, Spinner, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { NavSectionTitle } from './NavSectionTitle';
import { ScrollArea } from './ScrollArea';
import { LocaleContext } from '../../context/localeContext';
import { AddIcon } from '@chakra-ui/icons';
import { SidebarLink } from './SidebarLink';
import { NavLink } from 'react-router-dom';
import { IConfigurationDTO } from '../../types/configurationDto';

export interface SidebarProps {
  url: string;
  isLoading: boolean;
  isOpen: boolean;
  configurations: IConfigurationDTO[];
}

export const Sidebar: React.FC<SidebarProps> = ({
  configurations,
  isLoading,
  isOpen,
  url,
}) => {
  const { currentLocale } = React.useContext(LocaleContext);
  return (
    <Box
      as='nav'
      display='block'
      marginTop='var(--topbar-height)'
      flex='1'
      width={isOpen ? 'var(--sidebar-width)' : '0'}
      left='0'
      pt='6'
      pb='6'
      px={isOpen ? '6' : '0'}
      overflow='hidden'
      minH='100vh'
      bg={isOpen ? 'gray.50' : 'none'}
      borderRight='10px solid'
      borderColor='gray.50'
      color='gray.50'
      position='fixed'
      zIndex='9'
    >
      <Box fontSize='sm' lineHeight='tall'>
        <ScrollArea pt='5' pb='6'>
          <Stack pb='6'>
            <NavSectionTitle>{currentLocale.configurations}: </NavSectionTitle>

            {!isLoading ? (
              configurations.length &&
              configurations.map((c, index) => {
                return (
                  <SidebarLink
                    to={`${url}/${c.id}`}
                    key={index}
                    justifyContent='flex-start'
                    _hover={{ color: 'blue.500' }}
                  >
                    {c.name}
                  </SidebarLink>
                );
              })
            ) : (
              <Center>
                <Spinner size='md' />
              </Center>
            )}
          </Stack>

          <Box justifyContent='flex-start'>
            <Button leftIcon={<AddIcon />} size='sm' variant='ghost'>
              <Link
                _hover={{ textDecoration: 'none' }}
                as={NavLink}
                to={`${url}`}
              >
                {currentLocale.createNewConfTab}
              </Link>
            </Button>
          </Box>
        </ScrollArea>
      </Box>
    </Box>
  );
};
