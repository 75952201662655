import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import { IAkeneoCredentials } from '../types/AkeneoCredentials';
import { IConfigUpdateObject } from '../types/configUpdateObject';
import { IConfiguration } from '../types/Configuration';
import { IUser } from '../types/User';

export const fetchData = async (
  endpoint:
    | 'users'
    | 'configurations'
    | 'configuration-options/catalog-root'
    | 'configuration-options/channels'
    | 'configuration-options/categories'
    | 'configuration-options/attributes'
    | 'configuration-options/attribute-groups'
    | 'configuration-options/validate-root-catalog',
  token: string,
  queryParams = ''
) => {
  const url = `${config.API_URL}/${endpoint}?${queryParams}`;
  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, opts)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchConfigurationById = async (id: string, token: string) => {
  const url = `${config.API_URL}/configurations/${id}`;
  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, opts)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const akeneoAuthenticate = async (token: string) => {
  const url = `${config.API_URL}/auth/akeneo-auth`;
  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(url, '', opts)
    .then((res) => {
      if (res.status === 204) {
        return 'Akeneo auth success';
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const validateToken = async (token: string): Promise<boolean> => {
  const url = `${config.API_URL}/auth/test-auth`;
  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .get(url, opts)
    .then(() => true)
    .catch(() => false);
};

export const testAkeneoConnection = async (
  token: string,
  akeneoCredentials: any
) => {
  const url = `${config.API_URL}/auth/akeneo-auth-test`;
  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(url, { akeneoCredentials }, opts)
    .then((res) => {
      if (res.status !== 200) return false;
      return true;
    })
    .catch(() => false);
};

interface IUserUpdate {
  id: string;
  email?: string;
  oldPassword?: string;
  newPassword?: string;
  akeneoCredentials?: IAkeneoCredentials;
  akeneoVersion?: string;
  akeneoUrl?: string;
}

export const updateUser = async (
  token: string,
  data: IUserUpdate,
  admin = false
) => {
  const url = `${config.API_URL}/users/${admin ? 'admin/' : ''}${data.id}`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .put(url, data, opts)
    .then((res) => {
      if (res.status === 204) return;
      else throw res;
    })
    .catch((err) => {
      throw err;
    });
};

export const createConfiguration = async (
  token: string,
  configuration: Partial<IConfiguration>
) => {
  const url = `${config.API_URL}/configurations/`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(url, { configuration }, opts);
};

export const updateConfiguration = async (
  token: string,
  configId: string,
  data: IConfigUpdateObject
) => {
  const url = `${config.API_URL}/configurations/${configId}`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.put(url, { ...data }, opts);
};

export const deleteConfiguration = async (token: string, configId: string) => {
  const url = `${config.API_URL}/configurations/${configId}`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.delete(url, opts);
};

export const fetchUserById = async (user: IUser) => {
  const id = user.id;
  const token = user.token;
  const url = `${config.API_URL}/users/${id}`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.get(url, opts).then((res) => res.data);
};

export const createUser = async (token: string, data: any) => {
  const url = `${config.API_URL}/users`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(url, { ...data }, opts);
};

export const deleteUser = async (token: string, id: string) => {
  const url = `${config.API_URL}/users/${id}`;

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.delete(url, opts);
};
