import { Box, BoxProps } from '@chakra-ui/react';
export const Card = (props: BoxProps) => (
  <Box
    bg='#fff'
    py='8'
    px={{ base: '4', md: '10' }}
    shadow='base'
    rounded={{ sm: 'lg' }}
    {...props}
  />
);
