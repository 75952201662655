import React, { FC, useContext } from 'react';
import { Stack, StackDivider } from '@chakra-ui/react';
import { LocaleContext } from '../context/localeContext';
import { PageContent } from '../components/PageContent';
import { PageHeading } from '../components/PageHeading';
import UserSettings from '../components/SettingsComponents/UserSettings';
import AkeneoSettings from '../components/SettingsComponents/AkeneoSettings';
import FieldGroup from '../components/FieldGroup';

const SettingsView: FC = () => {
  const { currentLocale } = useContext(LocaleContext);

  return (
    <PageContent>
      <PageHeading>{currentLocale.settingsHeading}</PageHeading>

      <Stack spacing='4' divider={<StackDivider />}>
        <FieldGroup title={currentLocale.akeneoSettings}>
          <AkeneoSettings />
        </FieldGroup>

        <FieldGroup title={currentLocale.userSettings}>
          <UserSettings />
        </FieldGroup>
      </Stack>
    </PageContent>
  );
};

export default SettingsView;
