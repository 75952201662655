import React, { FC, useContext } from 'react';
import { Box, BoxProps, HStack, Flex, Text } from '@chakra-ui/react';
import { VincitLogo } from './VincitLogo';
import { HiOutlineMenu, HiOutlineKey, HiCog, HiX } from 'react-icons/hi';

import { MdFormatListBulleted } from 'react-icons/md';
import { NavItem } from './NavItem';
import { ProfileDropdown } from './ProfileDropdown';
import { NavLink } from 'react-router-dom';
import { LocaleContext } from '../../context/localeContext';
import { UserContext } from '../../context/userContext';
import { useMenuState } from '../../lib/useMenuState';

interface MobileMenuProps extends BoxProps {
  isOpen?: boolean;
}
const MobileMenu = (props: MobileMenuProps) => {
  const { isOpen, children } = props;
  return (
    <Flex
      hidden={!isOpen}
      as='nav'
      direction='column'
      bg='blue.900'
      position='fixed'
      height='60px'
      top='var(--topbar-height)'
      insetX='0'
      zIndex={10}
      w='full'
    >
      <Flex justify='space-between' px='4'>
        {children}
      </Flex>
    </Flex>
  );
};

const Topbar: FC = (props: BoxProps) => {
  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const { isOpen, onToggle } = useMenuState(true);
  return (
    <Flex
      align='center'
      justify='center'
      position='fixed'
      zIndex='sticky'
      boxSizing='border-box'
      w='100%'
      bg='blue.900'
      px={['2', '5']}
      top='0'
      color='white'
      h='var(--topbar-height)'
    >
      <Flex w='full'>
        <MobileMenu isOpen={isOpen}>
          <NavItem
            href='/configuration'
            icon={MdFormatListBulleted}
            label='Configurations'
          />
          {currentUser?.isAdmin && (
            <NavItem href='/admin' icon={HiOutlineKey} label='Admin' />
          )}
          <NavItem href='/settings' icon={HiCog} label='Global Settings' />
        </MobileMenu>
        <HStack justifyContent='left' maxWidth='320px' flex={[6, 4, 2, 3]}>
          <NavLink to='/'>
            <Flex align='left' h='5' flexShrink={0} mr='4'>
              <VincitLogo maxWidth='40px' h='5' />
              <Text
                color='white'
                ml='1'
                letterSpacing='tight'
                lineHeight='shorter'
                maxWidth={['80px', '100%']}
                alignItems='center'
                display='flex'
                fontSize={['8', '10']}
                textTransform='uppercase'
                fontWeight='bold'
              >
                {currentLocale.appTitle}
              </Text>
            </Flex>
          </NavLink>
        </HStack>

        <HStack
          justifyContent='left'
          spacing={['3', '3', '3', '6']}
          display={['none', 'none', 'flex']}
          flex={[0, 0, 4, 8]}
        >
          <NavItem
            href='/configuration'
            icon={MdFormatListBulleted}
            label='Configurations'
          />
          {currentUser?.isAdmin && (
            <NavItem
              href='/admin'
              icon={HiOutlineKey}
              label={currentLocale.admin}
            />
          )}
          <NavItem
            href='/settings'
            icon={HiCog}
            label={currentLocale.globalSettings}
          />
        </HStack>

        <HStack
          display='flex'
          flex='1'
          alignSelf='flex-end'
          justifyContent='flex-end'
          spacing='2'
        >
          <ProfileDropdown />
          <Box
            d={['block', 'block', 'none']}
            as='button'
            onClick={onToggle}
            p='2'
            fontSize='xl'
          >
            <Box aria-hidden as={isOpen ? HiX : HiOutlineMenu} />
            <Box srOnly>{isOpen ? 'Close menu' : 'Open menu'}</Box>
          </Box>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Topbar;
