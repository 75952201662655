import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export const VincitLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    aria-hidden
    width='709px'
    height='758px'
    viewBox='0 0 709 758'
    h='6'
    flexShrink={0}
    {...props}
  >
    <g
      id='One-pagerit'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Vincit-C'
        transform='translate(-305.000000, -2108.000000)'
        fill='#fff'
      >
        <path
          d='M684.99682,2683.7722 C757.539455,2683.76867 820.679982,2643.20105 853.966616,2583.21971 L1014,2680.59894 C947.511405,2791.59602 825.6474,2865.99647 686.256315,2866 C475.705569,2866.00706 305.007056,2696.33112 305,2487.01588 C304.989416,2277.69712 475.680873,2108.01059 686.231619,2108 C799.102765,2107.99647 900.483231,2156.78845 970.288141,2234.27039 L833.673588,2359.66892 C798.076118,2316.49986 744.708137,2289.01003 684.982708,2289.01003 C577.823386,2289.01356 490.96062,2377.38482 490.964148,2486.39817 C490.967676,2595.408 577.837498,2683.77573 684.99682,2683.7722 Z M865.837614,2405.90641 L968.34351,2395.78656 C974.118838,2395.21827 979.220319,2399.4434 979.562535,2405.24281 C981.079572,2431.12307 978.553528,2498.30514 913.818347,2521.89105 C906.600069,2524.52073 895.448076,2527.13628 878.115037,2529.45887 C876.216975,2529.70949 874.544706,2528.05755 874.925729,2526.18325 C877.539973,2513.32077 879.021731,2500.03826 879.021731,2486.38511 C879.018203,2459.12472 873.479251,2433.21975 863.643201,2409.60913 C862.94113,2407.92543 864.024224,2406.08643 865.837614,2405.90641 Z M732.800448,2420.95593 C732.800448,2407.45456 743.560833,2396.50875 756.83315,2396.50875 C770.10194,2396.50875 780.862325,2407.45456 780.862325,2420.9524 C780.862325,2434.45376 770.10194,2445.39957 756.83315,2445.39957 C743.560833,2445.39957 732.800448,2434.45376 732.800448,2420.95593 Z'
          id='Combined-Shape'
        ></path>
      </g>
    </g>
  </chakra.svg>
);
