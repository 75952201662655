import { extendTheme } from '@chakra-ui/react';

const Button = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'base',
  },
  variants: {

    ghost: {
      color: 'blue.600',
      _hover: {
        bg: 'blue.50',
      },
    },

    solid: {
      bg: 'blue.600',
      color: 'white',
      _hover: {
        bg: 'blue.700',
      },
    },

    alert: {
      bg: 'red.500',
      color: 'white',
      _hover: {
        bg: 'red.600',
      },
    },

    success: {
      bg: 'green.600',
      color: 'white',
      _hover: {
        bg: 'green.700',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const FormLabel = {
  baseStyle: {
    fontSize: 'xs',
  },
};


const Input = {
  baseStyle: {
    field: {
      bg: 'fff',
    },
  },
};
const Alert = {
  baseStyle: {
    container: {
      borderRadius: 'base',
    },
  },
};

const theme = extendTheme({
  colors: {
    red: {
      '50': '#FDEBE7',
      '100': '#FAC6BC',
      '200': '#F7A192',
      '300': '#F47D67',
      '400': '#F1583C',
      '500': '#EE3411',
      '600': '#BE290E',
      '700': '#8F1F0A',
      '800': '#5F1507',
      '900': '#300A03',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#EBF9F4',
      '100': '#C7EFE0',
      '200': '#A4E5CC',
      '300': '#80DBB8',
      '400': '#5CD1A5',
      '500': '#39C691',
      '600': '#2D9F74',
      '700': '#227757',
      '800': '#174F3A',
      '900': '#0B281D',
    },
    blue: {
      '50': '#E5F3FF',
      '100': '#B8DEFF',
      '200': '#8AC9FF',
      '300': '#5CB4FF',
      '400': '#2E9EFF',
      '500': '#0089FF',
      '600': '#006ECC',
      '700': '#005299',
      '800': '#003766',
      '900': '#001B33',
    },
    formInputBg: '#fff',
    navBar: '#505050',
    logoutBtnBg: 'lightblue',
    defaultBg: '#f6f5f5',
  },
  components: {
    Alert,
    Button,
    FormLabel,
    Input,
  },
});

export default theme;
