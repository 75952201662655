import { SkeletonText, Stack, StackDivider, useToast } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import RegisterUser from '../components/AdminPanel/RegisterUser';
import UserManagement from '../components/AdminPanel/UserManagement';
import FieldGroup from '../components/FieldGroup';
import { PageContent } from '../components/PageContent';
import { PageHeading } from '../components/PageHeading';
import { LocaleContext } from '../context/localeContext';
import { UserContext } from '../context/userContext';
import { fetchData } from '../lib/requests';
import { IUserDTO } from '../types/UserDto';

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<IUserDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);

    await fetchData('users', currentUser!.token)
      .then((res: IUserDTO[]) => {
        setUsers(res);
        setIsLoading(false);
      })
      .catch(() =>
        toast({
          title: currentLocale.usersFetchFailed,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  }, [currentLocale, currentUser, toast]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <PageContent>
      <PageHeading>{currentLocale.adminPanel}</PageHeading>

      {!isLoading ? (
        <Stack spacing='4' divider={<StackDivider />}>
          <FieldGroup title={currentLocale.userManagement}>
            <UserManagement fetchUsers={fetchUsers} users={users} />
          </FieldGroup>

          <FieldGroup title={currentLocale.registerUserHeading}>
            <RegisterUser fetchUsers={fetchUsers} />
          </FieldGroup>
        </Stack>
      ) : (
        <SkeletonText mt='4' noOfLines={5} spacing='4' />
      )}
    </PageContent>
  );
};

export default AdminPanel;
