import { createContext } from 'react';
import { ILanguage } from '../types/langs';
import locales from '../locales';

interface ILocaleContext {
  currentLocale: ILanguage;
  setCurrentLocale: (locale: any) => void;
}

export const LocaleContext = createContext<ILocaleContext>({
  currentLocale: locales.english,
  setCurrentLocale: () => {},
});
