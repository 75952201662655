import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { testAkeneoConnection, updateConfiguration } from '../../lib/requests';
import { IAkeneoCredentials } from '../../types/AkeneoCredentials';
import { UserContext } from '../../context/userContext';
import { IConfigUpdateObject } from '../../types/configUpdateObject';
import { LocaleContext } from '../../context/localeContext';
import { PageFooter } from '../PageFooter';
import { IConfigurationDTO } from '../../types/configurationDto';

export interface AkeneoCredentialsProps {
  configuration: IConfigurationDTO;
}

const AkeneoCredentials: FC<AkeneoCredentialsProps> = ({ configuration }) => {
  const { currentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  const [isValidCredentials, setIsValidCredentials] = useState(false);
  const [akeneoCredentials, setAkeneoCredentials] =
    useState<IAkeneoCredentials>({
      akeneoUrl: '',
      clientId: '',
      clientSecret: '',
      akeneoUsername: '',
      akeneoPassword: '',
    });

  useEffect(() => {
    setIsValidCredentials(false);
  }, [akeneoCredentials]);

  useEffect(() => {
    setAkeneoCredentials(configuration.akeneoCredentials);
  }, [configuration.akeneoCredentials]);

  const testConnection = async () => {
    const result: boolean = await testAkeneoConnection(
      currentUser!.token,
      akeneoCredentials
    );

    setIsValidCredentials(result);

    if (result) {
      toast({
        title: currentLocale.connectionSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      toast({
        title: currentLocale.connectionFail,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const data: IConfigUpdateObject = { akeneoCredentials };

    await updateConfiguration(currentUser!.token, configuration!.id, data)
      .then(() => {
        toast({
          title: currentLocale.credentialsSaved,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });

        setAkeneoCredentials({
          akeneoUrl: '',
          clientId: '',
          clientSecret: '',
          akeneoUsername: '',
          akeneoPassword: '',
        });
      })
      .catch(() =>
        toast({
          title: currentLocale.genericError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  };

  return (
    <VStack h='full' alignItems='flex-start' maxW='lg'>
      <Box w='full' mt='6'>
        <form onSubmit={handleSubmit}>
          <VStack w='full' alignItems='flex-start' spacing='6'>
            <FormControl>
              <FormLabel>{currentLocale.akeneoUrl}</FormLabel>
              <Input value={akeneoCredentials.akeneoUrl} isDisabled />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoClientId}</FormLabel>
              <Input
                value={akeneoCredentials.clientId}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    clientId: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoClientSecret}</FormLabel>
              <Input
                value={akeneoCredentials.clientSecret}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    clientSecret: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoUsername}</FormLabel>
              <Input
                value={akeneoCredentials.akeneoUsername}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    akeneoUsername: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoPassword}</FormLabel>
              <Input
                value={akeneoCredentials.akeneoPassword}
                type='password'
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    akeneoPassword: e.target.value,
                  })
                }
              />
            </FormControl>

            <PageFooter>
              <Button isDisabled={!isValidCredentials} type='submit'>
                {currentLocale.save}
              </Button>
              <Button variant='ghost' onClick={() => testConnection()}>
                {currentLocale.testConnection}
              </Button>
            </PageFooter>
          </VStack>
        </form>
      </Box>
    </VStack>
  );
};

export default AkeneoCredentials;
