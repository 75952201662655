import React, { FC, FormEvent, useContext, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Text,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  Stack,
  CloseButton,
} from '@chakra-ui/react';
import config from '../config';
import { Card } from '../components/Card';
import { IAlertBox } from '../types/AlertBox';
import { LocaleContext } from '../context/localeContext';

const Login: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertBox, setAlertBox] = useState<IAlertBox>({
    status: 'error',
    isVisible: false,
    message: 'null',
  });

  const { currentLocale } = useContext(LocaleContext);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const url = `${config.API_URL}/auth/login`;

    axios
      .post(url, { email, password })
      .then((res) => {
        if (res.status !== 200) {
          console.log(res.data);
          return;
        }

        localStorage.setItem(
          'user',
          JSON.stringify({
            user: res.data.user,
            token: res.data.token,
          })
        );

        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err);
        setAlertBox({
          isVisible: true,
          status: 'error',
          message: 'Login failed',
        });
      });
  };

  return (
    <Box bg='defaultBg' height='100vh' width='100%' position='relative'>
      <Flex
        direction='column'
        justify='center'
        minH='100vh'
        py='12'
        maxW={['sm', 'sm', 'md']}
        mx='auto'
        px={['6']}
      >
        <Stack spacing='4' mb='10'>
          <Text
            fontSize={{ base: '3xl', lg: '4xl' }}
            textAlign='center'
            lineHeight='shorter'
            fontWeight='extrabold'
            letterSpacing='tight'
          >
            {currentLocale.appTitle}
          </Text>
          <Text
            fontSize='xl'
            textAlign='center'
            lineHeight='short'
            fontWeight='light'
            letterSpacing='tight'
          >
            {currentLocale.loginToAcoount}
          </Text>
        </Stack>
        <Card bg='#fff' p={{ base: '6', md: '8', lg: '12' }}>
          {alertBox.isVisible && (
            <Alert status={alertBox.status}>
              <AlertIcon />
              {alertBox.message}
              <CloseButton
                position='absolute'
                right='8px'
                top='8px'
                onClick={() => setAlertBox({ ...alertBox, isVisible: false })}
              />
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <Stack spacing='6'>
              <FormControl isRequired>
                <FormLabel mt={4}>{currentLocale.email}:</FormLabel>
                <Input
                  bg='formInputBg'
                  type='email'
                  placeholder={currentLocale.emailPlaceholder}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>{currentLocale.password}:</FormLabel>
                <Input
                  bg='formInputBg'
                  type='password'
                  placeholder={currentLocale.passwordPlaceholder}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button
                fontWeight='bold'
                colorScheme='blue'
                size='lg'
                fontSize='md'
                type='submit'
              >
                {currentLocale.login}
              </Button>
            </Stack>
          </form>
        </Card>
      </Flex>
    </Box>
  );
};

export default Login;
