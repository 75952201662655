import { Box } from '@chakra-ui/react';

const FormWrapper: React.FC = ({ children }) => {
  return (
    <Box h='full' w={['100%', '100%', '50%']}>
      {children}
    </Box>
  );
};

export default FormWrapper;
