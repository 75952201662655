import { FC, FormEvent, useContext, useState } from 'react';
import { Box, Button, Stack, useToast, VStack } from '@chakra-ui/react';

import { createConfiguration } from '../../lib/requests';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';

import { IConfiguration } from '../../types/Configuration';
import { PageFooter } from '../PageFooter';
import ConfigurationForm from './ConfigurationForm';
import ConfigurationHeader from './ConfigurationHeader';

const CreateConfiguration: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [newConfiguration, setNewConfiguration] = useState<IConfiguration>({
    name: '',
    scope: '',
    locale: '',
    catalogRoot: '',
    category: '',
    attributes: [],
    completeness: 0,
    qualityScore: '',
  });

  const { currentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    await createConfiguration(currentUser!.token, newConfiguration)
      .then(() => {
        window.location.reload();
      })
      .catch(() =>
        toast({
          title: currentLocale.configCreateFailed,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  };

  return (
    <Box h='full' w='full'>
      <Stack spacing='6'>
        <ConfigurationHeader
          w={'200px'}
          title={currentLocale.newConfigHeader}
        />

        <form onSubmit={handleSubmit}>
          <VStack alignItems='flex-start' w='full'>
            <ConfigurationForm
              configuration={newConfiguration}
              setConfiguration={setNewConfiguration}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />

            {!isLoading && (
              <PageFooter>
                <Button type='submit'>{currentLocale.create}</Button>
              </PageFooter>
            )}
          </VStack>
        </form>
      </Stack>
    </Box>
  );
};

export default CreateConfiguration;
