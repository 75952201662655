import { FC, FormEvent, useContext, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { updateUser } from '../../lib/requests';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';
import { PageFooter } from '../PageFooter';

export interface UserSettingsProps {}

interface IPasswordChange {
  oldPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

const UserSettings: FC<UserSettingsProps> = () => {
  const { currentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  const [passwordChange, setPasswordChange] = useState<IPasswordChange>({
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (passwordChange.newPassword !== passwordChange.newPasswordAgain) {
      toast({
        title: currentLocale.passwordsMatchFailAlert,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });

      setPasswordChange({
        oldPassword: '',
        newPassword: '',
        newPasswordAgain: '',
      });

      return;
    }

    await updateUser(currentUser!.token, {
      id: currentUser!.id,
      ...passwordChange,
    })
      .then(() =>
        toast({
          title: currentLocale.passwordChangedAlert,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch(() =>
        toast({
          title: currentLocale.passwordChangeFailAlert,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .finally(() =>
        setPasswordChange({
          oldPassword: '',
          newPassword: '',
          newPasswordAgain: '',
        })
      );
  };

  return (
    <Box h='full' w='full'>
      <Heading size='md' mb='6'>
        {currentLocale.changePassword}
      </Heading>

      <Box w='60%'>
        <form onSubmit={handleSubmit}>
          <VStack spacing='6' alignItems='flex-start'>
            <FormControl isRequired>
              <FormLabel>{currentLocale.oldPassword}</FormLabel>
              <Input
                type='password'
                value={passwordChange.oldPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    oldPassword: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.newPassword}</FormLabel>
              <Input
                type='password'
                value={passwordChange.newPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    newPassword: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.newPasswordAgain}</FormLabel>
              <Input
                type='password'
                value={passwordChange.newPasswordAgain}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    newPasswordAgain: e.target.value,
                  })
                }
              />
            </FormControl>

            <PageFooter>
              <Button type='submit'>{currentLocale.changePassword}</Button>
            </PageFooter>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default UserSettings;
