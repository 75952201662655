import { ILanguages } from '../types/langs';

const locales: ILanguages = {
  english: {
    appTitle: 'Akeneo EasyCatalog InDesign Connector',
    loginToAcoount: 'Log in to your account',
    login: 'Login',
    logout: 'Logout',
    email: 'Email',
    emailPlaceholder: 'Enter email address',
    password: 'Password',
    passwordPlaceholder: 'Enter password',
    confirmPassword: 'Confirm password',
    loginFailed: 'Login failed',
    confFetchFailed: 'Configuration fetch failed',
    configurations: 'Configurations',
    configuration: 'Configuration',
    settingsHeading: 'Settings',
    akeneoSettings: 'Akeneo connection settings',
    userSettings: 'User settings',
    createUserTabTitle: 'Create new users',
    createNewConfTab: 'Create new',
    create: 'Create',
    globalSettings: 'Settings',
    rootCatalogFetchFail: 'Root category fetch failed',
    userDataFetchFail: 'User data fetch failed',
    scopeFetchFail: 'Channel fetch failed',
    attributeFetchFail: 'Attribute fetch failed',
    attGroupFetchFail: 'Attribute group fetch failed',
    categoryFetchFail: 'Category fetch failed',
    rootCatalogNotFound: 'Root catalog not found',
    configCreateFailed: 'Configuration create failed',
    newConfigHeader: 'New configuration',
    confName: 'Name',
    confNamePlaceholder: 'Configuration name',
    confScope: 'Channel',
    confScopePlaceholder: 'Select channel',
    confLocale: 'Language',
    confLocalePlaceholder: 'Select language',
    localesAll: 'All',
    confRootCatalog: 'Root catalog',
    confRootCatalogCode: 'Root category code (E.g. master)',
    confRootCatalogSearchAriaLabel: 'Search root catalog',
    confRootCatalogPlaceholder: 'Select root catalog',
    confCategory: 'Filter by category',
    confCategoryPlaceholder: 'Filter by category',
    confAttributes: 'Attributes',
    clearAttributes: 'Clear all',
    addAttributesByGroup: 'Add attributes by attribute group',
    addAttributes: 'Add attributes',
    confCompleteness: 'Completeness-%',
    confQualityScore: 'Quality Score',
    qualityScoreNone: 'None',
    connectionSuccess: 'Connection established',
    connectionFail: 'Connection failed',
    credentialsSaved: 'Credentials saved',
    genericError: 'An error occured!',
    globalCredentialsHeading: 'Set or update Akeneo credentials',
    confCredentialsHeading:
      'Set or update configuration specific Akeneo credentials',
    akeneoVersion: 'Akeneo Version',
    akeneoVersionPlaceholder: 'Select Akeneo version',
    akeneoEdition: 'Akeneo Edition',
    akeneoEditionPlaceholder: 'Select Akeneo Edition',
    akeneoUrl: 'Akeneo URL',
    akeneoClientId: 'Akeneo client ID',
    akeneoClientSecret: 'Akeneo client secret',
    akeneoUsername: 'Akeneo username',
    akeneoPassword: 'Akeneo password',
    save: 'Save',
    testConnection: 'Test connection',
    registerUserHeading: 'Register new user',
    adminChkBox: 'Admin',
    registerBtn: 'Register',
    newUserAlert: 'New user added',
    registerFailedAlert: 'Register failed',
    passwordsMatchFailAlert: "Passwords don't match",
    passwordChangedAlert: 'Password changed',
    passwordChangeFailAlert: 'Password change failed',
    changePassword: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    newPasswordAgain: 'New password again',
    akeneoCredentials: 'Akeneo credentials',
    endpointUrls: 'Endpoint URLs',
    xmlEndpoints: 'XML endpoints',
    products: 'Products',
    categories: 'Categories',
    attributeGroups: 'Attribute groups',
    confUpdateSuccess: 'Configuration updated',
    confUpdateFailed: 'Configuration update failed',
    confDeleteSuccess: 'Configuration deleted',
    confDeleteFailed: 'Configuration delete failed',
    editConfiguration: 'Edit configuration',
    delete: 'Delete',
    adminPanel: 'Admin panel',
    userUpdateAlert: 'User updated',
    userUpdateFailAlert: 'User update failed',
    userDeleted: 'User deleted',
    changeEmail: 'Change email',
    usersFetchFailed: 'Users fetch failed',
    userManagement: 'User management',
    copiedAlert: 'Copied to clipboard!',
    admin: 'Admin',
  },
};

export default locales;
