import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { fetchConfigurationById } from '../../lib/requests';
import { LocaleContext } from '../../context/localeContext';
import { UserContext } from '../../context/userContext';
import Endpoints from './Endpoints';
import EditConfiguration from './EditConfiguration';
import AkeneoCredentials from './AkeneoCredentials';
import ConfigurationHeader from './ConfigurationHeader';
import { IConfigurationDTO } from '../../types/configurationDto';
import { IConfiguration } from '../../types/Configuration';

export interface IConfigurationViewProps {
  getConfigurations: (token: string) => void;
}

interface IConfigParams {
  confId: string;
}

const ConfigurationView: FC<IConfigurationViewProps> = ({
  getConfigurations,
}) => {
  const [configuration, setConfiguration] = useState<
    IConfigurationDTO | undefined
  >();

  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const { confId } = useParams<IConfigParams>();
  const toast = useToast();

  useEffect(() => {
    setConfiguration(undefined);

    fetchConfigurationById(confId, currentUser!.token)
      .then((c: IConfigurationDTO) => setConfiguration(c))
      .catch(() =>
        toast({
          title: currentLocale.confFetchFailed,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );
  }, [currentUser, currentLocale, confId, toast]);

  return (
    <Box h='full' w='full' my='6'>
      {configuration ? (
        <>
          <ConfigurationHeader
            w={'200px'}
            title={`${currentLocale.configuration}: ${configuration.name}`}
          />

          <Tabs h='full'>
            <TabList>
              <Tab>{currentLocale.configurations}</Tab>
              <Tab>{currentLocale.akeneoCredentials}</Tab>
              <Tab>{currentLocale.endpointUrls}</Tab>
            </TabList>

            <TabPanels h='full'>
              <TabPanel>
                <EditConfiguration
                  confId={confId}
                  getConfigurations={getConfigurations}
                  configuration={configuration as IConfiguration}
                />
              </TabPanel>

              <TabPanel>
                <AkeneoCredentials configuration={configuration} />
              </TabPanel>

              <TabPanel>
                <Endpoints enpointUrls={configuration.urls} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <SkeletonText mt='4' noOfLines={5} spacing='4' />
      )}
    </Box>
  );
};

export default ConfigurationView;
