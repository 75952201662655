import React, { useEffect, useState } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import locales from './locales';
import { UserContext } from './context/userContext';
import { LocaleContext } from './context/localeContext';

import Main from './views/Main';
import Login from './views/Login';
import SettingsView from './views/SettingsView';
import { IUser } from './types/User';
import { ILanguage } from './types/langs';
import Logout from './components/Logout';
import AdminPanel from './views/AdminPanel';
import ViewContainer from './views/ViewContainer';

const App = () => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [currentLocale, setCurrentLocale] = useState<ILanguage>(
    locales['english']
  );

  const userContextValue = { currentUser, setCurrentUser };
  const localeContextValue = { currentLocale, setCurrentLocale };

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');

    if (loggedInUser && !currentUser) {
      const { token, user } = JSON.parse(loggedInUser);
      setCurrentUser({
        id: user.id,
        email: user.email,
        isAdmin: user.isAdmin,
        token,
      });
    } else if (currentUser && !loggedInUser) {
      setCurrentUser(null);
    }
  }, []);

  return (
    <LocaleContext.Provider value={localeContextValue}>
      {currentUser ? (
        <UserContext.Provider value={userContextValue}>
          <Router>
            <ViewContainer>
              <Switch>
                <Route exact path='/settings' component={SettingsView} />
                <Route exact path='/admin' component={AdminPanel} />
                <Route exact path='/logout' component={Logout} />
                <Route path='/configuration' component={Main} />
                <Route exact path='/'>
                  <Redirect to='/configuration' />
                </Route>
              </Switch>
            </ViewContainer>
          </Router>
        </UserContext.Provider>
      ) : (
        <Login />
      )}
    </LocaleContext.Provider>
  );
};

export default App;
