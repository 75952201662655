import { Box, BoxProps, HStack } from '@chakra-ui/react';
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface NavItemProps extends BoxProps {
  href?: string;
  active?: boolean;
  label?: string;
  srOnlyLabel?: string;
}

interface DesktopNavItemProps extends NavItemProps {
  icon?: React.ReactNode | any;
}
const NavItemWrapper = (props: NavLinkProps) => {
  const { to, children } = props;
  return to ? (
    <NavLink to={to}>{children}</NavLink>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export const NavItem = (props: DesktopNavItemProps) => {
  const { href, display, icon, label, active, srOnlyLabel } = props;
  return (
    <HStack
      aria-current={active ? 'page' : undefined}
      spacing='5'
      px='3'
      py='1'
      display={display ? display : 'inline-block'}
      cursor='pointer'
      rounded='md'
      transition='all 0.2s'
      _hover={{ bg: 'whiteAlpha.200' }}
      _activeLink={{ color: 'white' }}
    >
      <NavItemWrapper to={href}>
        {icon && (
          <Box
            aria-hidden
            w={['5', '6']}
            h={['5', '6']}
            display='inline-block'
            as={icon}
          ></Box>
        )}
        {label && (
          <Box
            display={{ base: 'inline-block', md: 'inline-block' }}
            fontWeight='semibold'
            ml='1'
            pt='1'
            fontSize={['11', 'xs']}
          >
            {label}
          </Box>
        )}
        {srOnlyLabel && <Box srOnly>{srOnlyLabel}</Box>}
      </NavItemWrapper>
    </HStack>
  );
};
