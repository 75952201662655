import * as React from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

interface PageContentProps extends BoxProps {
  isOpen?: boolean;
}
export const PageContent = (props: PageContentProps) => {
  const { isOpen, children } = props;
  return (
    <Box
      flex='1'
      p={{ base: '4', md: '10', xl: '10' }}
      position='relative'
      marginStart={isOpen ? 'var(--sidebar-width)' : '4px'}
      transition='left 0.2s'
      width='auto'
      marginTop='calc(var(--topbar-height))'
      minHeight='calc(100vh - var(--topbar-height))'
    >
      <Box maxW='2560px' bg='white' pb='6' rounded='lg'>
        <Flex direction='column' height='full'>
          <Flex
            direction='column'
            flex='1'
            maxWidth='1400px'
            px={['5', '10', '10', '14']}
            pt='8'
          >
            {children}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
