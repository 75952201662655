import { useEffect } from 'react';

const Logout: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('user');
    window.location.href = '/';
  });

  return null;
};

export default Logout;
