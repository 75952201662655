import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {
  fetchUserById,
  testAkeneoConnection,
  updateUser,
} from '../../lib/requests';
import { IAkeneoCredentials } from '../../types/AkeneoCredentials';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';
import { PageFooter } from '../PageFooter';

const AkeneoSettings: FC = () => {
  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  const [isValidCredentials, setIsValidCredentials] = useState(false);
  const [akeneoVersion, setAkeneoVersion] = useState('');
  const [akeneoEdition, setAkeneoEdition] = useState('');
  const [userHasCredentials, setUserHasCredentials] = useState(false);

  const [akeneoCredentials, setAkeneoCredentials] =
    useState<IAkeneoCredentials>({
      akeneoUrl: '',
      clientId: '',
      clientSecret: '',
      akeneoUsername: '',
      akeneoPassword: '',
    });

  useEffect(() => {
    fetchUserById(currentUser!).then((user) => {
      setAkeneoCredentials({ ...user.akeneoCredentials });
      setAkeneoVersion(user.akeneoVersion.version);
      setAkeneoEdition(user.akeneoVersion.edition);

      if (user.akeneoCredentials.akeneoUrl && !user.isAdmin) {
        setUserHasCredentials(true);
      }
    });
  }, [currentUser]);

  useEffect(() => {
    setIsValidCredentials(false);
  }, [akeneoCredentials]);

  const testConnection = async () => {
    const result: boolean = await testAkeneoConnection(
      currentUser!.token,
      akeneoCredentials
    );

    if (result) {
      toast({
        title: currentLocale.connectionSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      toast({
        title: currentLocale.connectionFail,
        description:
          'Refresh page and try again, or check your Akeneo connection account',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    }

    setIsValidCredentials(result);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    await updateUser(currentUser!.token, {
      id: currentUser!.id,
      akeneoCredentials,
      akeneoVersion: `${akeneoVersion}/${akeneoEdition}`,
    })
      .then(() => {
        toast({
          title: currentLocale.credentialsSaved,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      })
      .catch(() => {
        toast({
          title: currentLocale.genericError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      });
  };

  return (
    <Box h='full' w='full'>
      <form onSubmit={handleSubmit}>
        <VStack spacing='6' w='full' alignItems='flex-start'>
          <Grid w='full' templateColumns='repeat(2, 1fr)' gap={6}>
            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoVersion}:</FormLabel>
              <Select
                placeholder={currentLocale.akeneoVersionPlaceholder}
                value={akeneoVersion}
                onChange={(e) => setAkeneoVersion(e.target.value)}
              >
                <option value='saas'>Serenity</option>
                <option value='5'>5.0</option>
                <option value='4'>4.0</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoEdition}:</FormLabel>
              <Select
                placeholder={currentLocale.akeneoEditionPlaceholder}
                value={akeneoEdition}
                onChange={(e) => setAkeneoEdition(e.target.value)}
              >
                <option value='ee'>Enterprise</option>
                <option value='ce'>Community</option>
              </Select>
            </FormControl>

            <FormControl isDisabled={userHasCredentials} isRequired>
              <FormLabel>{currentLocale.akeneoUrl}:</FormLabel>
              <Input
                value={akeneoCredentials.akeneoUrl}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    akeneoUrl: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoClientId}:</FormLabel>
              <Input
                value={akeneoCredentials.clientId}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    clientId: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoClientSecret}:</FormLabel>
              <Input
                value={akeneoCredentials.clientSecret}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    clientSecret: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoUsername}:</FormLabel>
              <Input
                value={akeneoCredentials.akeneoUsername}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    akeneoUsername: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{currentLocale.akeneoPassword}:</FormLabel>
              <Input
                type='password'
                value={akeneoCredentials.akeneoPassword}
                onChange={(e) =>
                  setAkeneoCredentials({
                    ...akeneoCredentials,
                    akeneoPassword: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>

          <PageFooter>
            <Button isDisabled={!isValidCredentials} type='submit'>
              {currentLocale.save}
            </Button>
            <Button onClick={() => testConnection()} variant='ghost'>
              {currentLocale.testConnection}
            </Button>
          </PageFooter>
        </VStack>
      </form>
    </Box>
  );
};

export default AkeneoSettings;
