import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useToast, IconButton } from '@chakra-ui/react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../context/userContext';
import { LocaleContext } from '../context/localeContext';
import { akeneoAuthenticate, fetchData, validateToken } from '../lib/requests';
import CreateConfiguration from '../components/ConfigurationView/CreateConfiguration';
import ConfigurationView from '../components/ConfigurationView/';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { PageContent } from '../components/PageContent';
import { IConfigurationDTO } from '../types/configurationDto';
import { useMenuState } from '../lib/useMenuState';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

const Main: FC = () => {
  const [configurations, setConfigurations] = useState<IConfigurationDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const { path, url } = useRouteMatch();
  const toast = useToast();
  const { isOpen, onToggle } = useMenuState();

  const getConfigurations = useCallback(
    (token: string) => {
      setIsLoading(true);

      fetchData('configurations', token)
        .then((data: IConfigurationDTO[]) => setConfigurations(data))
        .catch(() =>
          toast({
            title: currentLocale.confFetchFailed,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          })
        )
        .finally(() => setIsLoading(false));
    },
    [currentLocale, toast]
  );

  useEffect(() => {
    const token = currentUser!.token;
    setIsLoading(true);

    validateToken(token).then((isValid) => {
      if (isValid) {
        akeneoAuthenticate(token)
          .then(() => getConfigurations(token))
          .then(() => setIsLoading(false))
          .catch(() => {
            window.location.href = '/settings';
            setIsLoading(false);
          });
      } else {
        console.log('Invalid or expired token');
        localStorage.removeItem('user');
        setCurrentUser(null);
      }
    });
  }, [currentLocale, currentUser, getConfigurations, setCurrentUser, toast]);

  return (
    <>
      <IconButton
        onClick={onToggle}
        variant='unstyled'
        display='flex'
        cursor='pointer'
        position='fixed'
        top='var(--topbar-height)'
        left={isOpen ? 'var(--sidebar-width)' : '10px'}
        zIndex='9'
        borderTopRightRadius='0'
        size='md'
        borderTopLeftRadius='0'
        borderBottomLeftRadius='0'
        bg='gray.50'
        aria-label='Menu'
        icon={
          isOpen ? (
            <FiChevronsLeft fontSize='1.25rem' />
          ) : (
            <FiChevronsRight fontSize='1.25rem' />
          )
        }
      >
        Configurations
      </IconButton>
      <Sidebar
        url={url}
        isOpen={isOpen}
        isLoading={isLoading}
        configurations={configurations}
      />

      {!isLoading && (
        <PageContent isOpen={isOpen}>
          <Switch>
            <Route exact path={path}>
              <CreateConfiguration />
            </Route>
            <Route path={`${path}/:confId`}>
              <ConfigurationView getConfigurations={getConfigurations} />
            </Route>
          </Switch>
        </PageContent>
      )}
    </>
  );
};

export default Main;
