import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Box, Button, useToast, VStack } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { deleteConfiguration, updateConfiguration } from '../../lib/requests';
import { UserContext } from '../../context/userContext';
import { LocaleContext } from '../../context/localeContext';
import { IConfigUpdateObject } from '../../types/configUpdateObject';
import { IConfiguration } from '../../types/Configuration';
import { PageFooter } from '../PageFooter';
import ConfigurationForm from './ConfigurationForm';

export interface IEditConfigurationProps {
  getConfigurations: (token: string) => void;
  configuration: IConfiguration;
  confId: string;
}

const EditConfiguration: FC<IEditConfigurationProps> = ({
  configuration,
  confId,
  getConfigurations,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modifiedConfiguration, setModifiedConfiguration] =
    useState<IConfiguration>(configuration);

  const { currentUser } = useContext(UserContext);
  const { currentLocale } = useContext(LocaleContext);
  const toast = useToast();

  useEffect(() => {
    setModifiedConfiguration(configuration);
  }, [configuration]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const token = currentUser!.token;

    const data: IConfigUpdateObject = {
      configuration: {
        name: modifiedConfiguration.name,
        scope: modifiedConfiguration.scope,
        locale: modifiedConfiguration.locale,
        catalogRoot: modifiedConfiguration.catalogRoot,
        category: modifiedConfiguration.category,
        attributes: modifiedConfiguration.attributes,
        completeness: modifiedConfiguration.completeness,
        qualityScore: modifiedConfiguration.qualityScore,
      },
    };

    await updateConfiguration(token, confId, data)
      .then(() =>
        toast({
          title: currentLocale.confUpdateSuccess,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch(() =>
        toast({
          title: currentLocale.confUpdateFailed,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .finally(() => {
        getConfigurations(token);
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    await deleteConfiguration(currentUser!.token, confId)
      .then(() =>
        toast({
          title: currentLocale.confDeleteSuccess,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch(() =>
        toast({
          title: currentLocale.confDeleteFailed,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      );

    window.location.href = '/';
  };

  return (
    <Box h='full' w='full' my='6'>
      <form onSubmit={handleSubmit}>
        <VStack alignItems='flex-start' w='full'>
          <ConfigurationForm
            configuration={modifiedConfiguration}
            setConfiguration={setModifiedConfiguration}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />

          {!isLoading && (
            <PageFooter>
              <Button type='submit'>{currentLocale.save}</Button>
              <Button
                variant='alert'
                leftIcon={<DeleteIcon />}
                onClick={() => handleDelete()}
              >
                {currentLocale.delete}
              </Button>
            </PageFooter>
          )}
        </VStack>
      </form>
    </Box>
  );
};

export default EditConfiguration;
