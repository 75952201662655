import { BoxProps, Flex, Spacer } from '@chakra-ui/react';
import { FC } from 'react';
import { PageHeading } from '../PageHeading';
import Logo from '../Logo';

interface ConfigurationHeaderProps extends BoxProps {
  title: string;
}

const ConfigurationHeader: FC<ConfigurationHeaderProps> = (props) => {
  const { title, ...rest } = props;
  return (
    <>
      <Flex maxH={'100px'} overflow={'visible'}>
        <PageHeading>{title}</PageHeading>
        <Spacer />
        <Logo mt={-10} {...rest} />
      </Flex>
    </>
  );
};

export default ConfigurationHeader;
