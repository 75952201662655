import { StackProps, Stack, Box, Heading } from '@chakra-ui/layout';

export interface FieldGroupProps extends StackProps {
  title: string;
}

const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, ...flexProps } = props;
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing='6'
      py='4'
      {...flexProps}
    >
      <Box minW='3xs'>
        <Heading as='h2' fontWeight='semibold' fontSize='lg' flexShrink={0}>
          {title}
        </Heading>
      </Box>
      {children}
    </Stack>
  );
};
export default FieldGroup;
