import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { CheckCircleIcon, DeleteIcon, WarningIcon } from '@chakra-ui/icons';
import { LocaleContext } from '../../context/localeContext';
import { UserContext } from '../../context/userContext';
import { PageFooter } from '../PageFooter';
import { IUserDTO } from '../../types/UserDto';
import {
  deleteUser,
  testAkeneoConnection,
  updateUser,
} from '../../lib/requests';

export interface UserEditFormProps {
  user: IUserDTO;
  setUser: Dispatch<SetStateAction<IUserDTO>>;
  fetchUsers: () => void;
}

const UserEditForm: React.FC<UserEditFormProps> = ({
  user,
  setUser,
  fetchUsers,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCredentials, setIsValidCredentials] = useState(false);

  const { currentLocale } = useContext(LocaleContext);
  const { currentUser } = useContext(UserContext);
  const toast = useToast();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      id: user.id,
      email: user.email,
      newPassword,
      akeneoVersion: `${user.akeneoVersion.version}/${user.akeneoVersion.edition}`,
      akeneoCredentials: user.akeneoCredentials,
    };

    await updateUser(currentUser!.token, data, true)
      .then(() =>
        toast({
          title: currentLocale.userUpdateAlert,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch(() =>
        toast({
          title: currentLocale.userUpdateFailAlert,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .finally(() => {
        setNewPassword('');
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);

    await deleteUser(currentUser!.token, user.id)
      .then(() =>
        toast({
          title: currentLocale.userDeleted,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .catch(() =>
        toast({
          title: currentLocale.genericError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        })
      )
      .finally(() => {
        fetchUsers();
        setIsLoading(false);
      });
  };

  const testConnection = async () => {
    const result: boolean = await testAkeneoConnection(
      currentUser!.token,
      user.akeneoCredentials
    );

    if (result) {
      toast({
        title: currentLocale.connectionSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      toast({
        title: currentLocale.connectionFail,
        description: 'Check your Akeneo account credentials and permissions',
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    }

    setIsValidCredentials(result);
  };

  useEffect(() => {
    setIsValidCredentials(false);
  }, [user.akeneoCredentials]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <VStack spacing='6' alignItems='flex-start'>
          <HStack w='full' spacing='4'>
            <FormControl>
              <FormLabel>{currentLocale.changeEmail}</FormLabel>

              <Input
                bg='formInputBg'
                type='email'
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{currentLocale.changePassword}</FormLabel>

              <Input
                bg='formInputBg'
                type='password'
                placeholder={currentLocale.passwordPlaceholder}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel>{currentLocale.akeneoUrl}</FormLabel>

            <Input
              bg='formInputBg'
              value={user.akeneoCredentials.akeneoUrl}
              onChange={(e) =>
                setUser({
                  ...user,
                  akeneoCredentials: {
                    ...user.akeneoCredentials,
                    akeneoUrl: e.target.value,
                  },
                })
              }
            />
          </FormControl>
          <HStack w='full' spacing='4'>
            <FormControl>
              <FormLabel>{currentLocale.akeneoVersion}</FormLabel>
              <Select
                value={user.akeneoVersion.version}
                placeholder={currentLocale.akeneoVersionPlaceholder}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoVersion: {
                      ...user.akeneoVersion,
                      version: e.target.value,
                    },
                  })
                }
              >
                <option value='saas'>Serenity</option>
                <option value='5'>5.0</option>
                <option value='4'>4.0</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>{currentLocale.akeneoEdition}</FormLabel>
              <Select
                value={user.akeneoVersion.edition}
                placeholder={currentLocale.akeneoEditionPlaceholder}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoVersion: {
                      ...user.akeneoVersion,
                      edition: e.target.value,
                    },
                  })
                }
              >
                <option value='ee'>Enterprise</option>
                <option value='ce'>Community</option>
              </Select>
            </FormControl>
          </HStack>

          <HStack w='full' spacing='4'>
            <FormControl>
              <FormLabel>{currentLocale.akeneoClientId}:</FormLabel>
              <Input
                value={user.akeneoCredentials.clientId}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoCredentials: {
                      ...user.akeneoCredentials,
                      clientId: e.target.value,
                    },
                  })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>{currentLocale.akeneoClientSecret}:</FormLabel>
              <Input
                value={user.akeneoCredentials.clientSecret}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoCredentials: {
                      ...user.akeneoCredentials,
                      clientSecret: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </HStack>

          <HStack w='full' spacing='4'>
            <FormControl>
              <FormLabel>{currentLocale.akeneoUsername}:</FormLabel>
              <Input
                value={user.akeneoCredentials.akeneoUsername}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoCredentials: {
                      ...user.akeneoCredentials,
                      akeneoUsername: e.target.value,
                    },
                  })
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>{currentLocale.akeneoPassword}:</FormLabel>
              <Input
                type='password'
                value={user.akeneoCredentials.akeneoPassword}
                onChange={(e) =>
                  setUser({
                    ...user,
                    akeneoCredentials: {
                      ...user.akeneoCredentials,
                      akeneoPassword: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </HStack>

          <PageFooter>
            <Button isLoading={isLoading} type='submit'>
              {currentLocale.save}
            </Button>

            <Button
              leftIcon={
                isValidCredentials ? <CheckCircleIcon /> : <WarningIcon />
              }
              onClick={() => testConnection()}
              variant='ghost'
            >
              {currentLocale.testConnection}
            </Button>

            <IconButton
              aria-label={currentLocale.delete}
              variant='alert'
              icon={<DeleteIcon />}
              onClick={() => handleDelete()}
              isDisabled={currentUser!.email === user.email}
            />
          </PageFooter>
        </VStack>
      </form>
    </>
  );
};

export default UserEditForm;
