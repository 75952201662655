import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'

export const useMenuState = (hideOnDesktop: boolean = false) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const isMobile = useBreakpointValue({ base: true, lg: false })
    React.useEffect(() => {
        if (!hideOnDesktop) {
            onOpen()
        }
        if (isMobile === true) {
            onClose()
        }
    }, [isMobile, onOpen, onClose, hideOnDesktop])

    return { isOpen, onClose, onOpen, onToggle }
}
