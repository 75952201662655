import { FC } from 'react';
import { Box, BoxProps, Image } from '@chakra-ui/react';

const Logo: FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <Image
        alt='Print connector logo'
        src='https://res.cloudinary.com/ditq0jo4n/image/upload/v1641909271/Akeneo_EasyCatalog_InDesign_Connector.svg'
      />
    </Box>
  );
};

export default Logo;
